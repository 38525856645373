import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import { theme } from './theme'
import { BREAKPOINTS } from '@/components/ds/breakpoints'
import { isTestKitPaymentElementEnabled } from '@/lib/utils/flags'
import {
  buttonStyles,
  colorVariables,
  fontFaceDeclarations,
  fontVariables,
  formFieldStyles,
  gridSpacingVariables,
  radiusVariables,
  smallBreakpointFontVariables,
} from '@zoe/ds-web'

const isPaymentElementEnabled = isTestKitPaymentElementEnabled()

export const GlobalStyles = createGlobalStyle`

  ${fontFaceDeclarations}
  ${normalize}

  * {
    box-sizing: border-box;
  }
  *, html, body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html {
    -webkit-text-size-adjust: 100%;

    ${fontVariables}
    ${colorVariables}
    ${gridSpacingVariables}
    ${radiusVariables}
    ${formFieldStyles}
    ${buttonStyles}
    font-size: var(--font-size-16);
    line-height: var(--font-size-16) * var(--line-height-ratio-paragraph);
  }
  
  @media screen and (max-width: ${BREAKPOINTS.m}) {
    html {
      ${smallBreakpointFontVariables}
    }
  }

  body {
    margin: 0;
    background: var(--color-surface-neutral);
    color: var(--color-text-primary);
    transition: all 0.3s ease;
    font-family: var(--font-sans-serif-regular);
    font-weight: var(--font-sans-serif-regular-weight);
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  // TODO: Remove this once we migrate fully to the Design System
  a {
    text-decoration: none;
    color: var(--color-text-link);
    &:hover {
      color: var(--color-text-link-hover);
    }
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: var(--font-sans-serif-regular-weight);
  }
  h1 {
    font-size: var(--font-size-40);
    line-height: var(--font-size-40) * var(--line-height-ratio-heading);
    letter-spacing: var(--font-size-40) * var(--letter-spacing-ratio-heading);
  }
  h2 {
    font-size: var(--font-size-30);
    line-height: var(--font-size-30) * var(--line-height-ratio-heading);
  }
  h3 {
    font-size: var(--font-size-24);
    line-height: var(--font-size-24) * var(--line-height-ratio-heading);
  }
  h4 {
    font-size: var(--font-size-20);
    line-height: var(--font-size-20) * var(--line-height-ratio-heading);
  }
  h5 {
    font-size: var(--font-size-18);
    line-height: var(--font-size-18) * var(--line-height-ratio-heading);
  }
  p {
    font-size: var(--font-size-20);
    line-height: var(--font-size-20) * var(--line-height-ratio-paragraph);

    &.small {
    font-size: var(--font-size-16);
    line-height: var(--font-size-16) * var(--line-height-ratio-paragraph);
    }
    &.secondary {
      color: var(--color-text-secondary);
    }
  }
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid var(-color-divider-primary);
    margin: 1em 0;
    padding: 0;
  }
  form {
    div.error {
      color: var(--color-text-error);
      font-size: var(--font-size-16);
      line-height: var(--font-size-16) * var(--line-height-ratio-paragraph);
      margin-top: var(--grid-8);
    }
  }

  //Mobile
  @media screen and (max-width: ${BREAKPOINTS.m}) {
    h1 {
      font-size: var(--font-size-24);
      line-height: var(--font-size-24) * var(--line-height-ratio-heading);
      letter-spacing: var(--font-size-24) * var(--letter-spacing-ratio-heading);
    }
    h2 {
      font-size: var(--font-size-20);
      line-height: var(--font-size-20) * var(--line-height-ratio-heading);
    }
    h3 {
      font-size: var(--font-size-18);
      line-height: var(--font-size-18) * var(--line-height-ratio-heading);
    }
    h4 {
      font-size: var(--font-size-18);
      line-height: var(--font-size-18) * var(--line-height-ratio-heading);
    }

  }


  // TODO: Move this to the checkout if still needed
  .checkout-v2 .StripeElement {
    background-color: var(--color-surface-neutral);
  }

  .StripeElement {
    padding: 20px 14px;
    border: 1px solid ${theme.v2.onSurface.subdued.border};
    background-color: ${theme.v2.surface.cream100};
    font-size: ${theme.v2.typography.body.xs.fontSize};
    line-height: ${theme.v2.typography.body.xs.lineHeight};
    font-family: ${theme.v2.font.lotaGrotesqueLight};
    padding: 16px 12px;
    background-color: ${theme.v2.surface.cream100};
    border-radius: ${isPaymentElementEnabled ? '8px' : '4px'}; // TODO: Remove this once we decide to use Payment Element or Card Element
    color: ${theme.v2.onSurface.text};

    .ElementsApp, .ElementsApp .InputElement {
      background-color: transparent;
      input {
        background-color: transparent;
      }
      &::placeholder {
        background-color: transparent;
      }
    }
  }

`
