import { createContext, ReactNode, useEffect, useState } from 'react'
import axios from 'axios'
import * as Sentry from '@sentry/nextjs'
import { useKameleoonApi } from '@/lib/kamaleoon/useKameleoonApi'

type GeoContextType = {
  country: string
}

type Props = {
  children: ReactNode
}

export const GeoContext = createContext<GeoContextType>({ country: '' })

export const GeoContextProvider = (props: Props) => {
  const { setCountryCustomData } = useKameleoonApi()
  const [countryCode, setCountryCode] = useState('')

  const getCountryCode = async (remainingAttempts: number) => {
    try {
      const result = await axios('https://getipcountry.zoe.net')
      setCountryCode(result.data)
    } catch (exception) {
      if (remainingAttempts > 0) {
        await getCountryCode(remainingAttempts - 1)
      } else {
        if (exception.message == 'Network Error') {
          Sentry.captureException(new Error('GEO-IP lookup: Call to getipcountry.zoe.net failed with Network Error'))
        } else {
          if (exception.message == 'Request failed with status code 403') {
            Sentry.captureException(new Error('GEO-IP lookup: Call to getipcountry.zoe.net failed with 403'))
          } else {
            throw exception
          }
        }
      }
    }
  }

  useEffect(() => {
    if (countryCode) {
      setCountryCustomData(countryCode)
    }
  }, [countryCode, setCountryCustomData])

  useEffect(() => {
    getCountryCode(5)
  }, [])

  return <GeoContext.Provider value={{ country: countryCode }}>{props.children}</GeoContext.Provider>
}
