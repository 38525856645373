import { createContext, ReactNode, useContext, useState } from 'react'

type ExperimentContextType = {
  variant: string | null
  setVariant: (variant: string | null) => void
  shouldRenderContent: (dataTestId?: string) => boolean
}

const ExperimentContext = createContext<ExperimentContextType>({
  variant: null,
  setVariant: () => {},
  shouldRenderContent: () => true,
})

export const ExperimentProvider = (props: { children: ReactNode }) => {
  const [variant, setVariant] = useState<string | null>(null)

  const shouldRenderContent = (dataTestId?: string) => {
    return !variant || !dataTestId || variant === dataTestId
  }

  return (
    <ExperimentContext.Provider value={{ variant, setVariant, shouldRenderContent }}>
      {props.children}
    </ExperimentContext.Provider>
  )
}

export const useExperimentContext = () => {
  return useContext(ExperimentContext)
}
